import React from 'react';
import PropTypes from 'prop-types';
import { Field, withFormik } from 'formik';
import FormField from '@firsttable/web-components/molecules/FormField/FormField';
import { graphql } from '@apollo/client/react/hoc';
import FormLayout from '@firsttable/web-components/organisms/Forms/Form';
import { flowRight as compose } from 'lodash';

import * as Yup from 'yup';
import { updateReview as UPDATE_REVIEW } from '@firsttable/graphql-queries/src';
import { errorMessage } from '@firsttable/functions';

const MisuseReview = ({ booking, ...props }) => (
  <FormLayout {...props} actionLabel="Update review">
    {booking.misuseReview && (
      <Field
        component={FormField}
        type="textarea"
        name="comment"
        id="Comment"
        placeholder="Update your feedback here"
      />
    )}
  </FormLayout>
);

MisuseReview.defaultProps = {};
MisuseReview.propTypes = {
  booking: PropTypes.shape(),
};

export default compose(
  graphql(UPDATE_REVIEW, { name: 'updateReviewQuery' }),
  withFormik({
    mapPropsToValues: () => ({
      comment: '',
    }),
    validationSchema: () =>
      Yup.object().shape({
        comment: Yup.string(),
      }),
    handleSubmit: async (
      values,
      {
        setSubmitting,
        setStatus,
        resetForm,
        props: { updateReviewQuery, booking, refetch },
      },
    ) => {
      setSubmitting(true);
      try {
        const { error } = await updateReviewQuery({
          variables: {
            reviewId: +booking.misuseReview.id,
            comment: values.comment,
          },
        });

        if (!error) {
          await refetch();
          return setSubmitting(false);
        }
        // resetForm();
        setStatus({ message: errorMessage(error), type: 'danger' });
        return setSubmitting(false);
      } catch (e) {
        resetForm();
        setStatus({ message: errorMessage(e), type: 'danger' });
        return setSubmitting(false);
      }
    },
  }),
)(MisuseReview);
