import React from 'react';
import { Router } from '@reach/router';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Link, Text, Title } from '@firsttable/web-components/atoms';
import ContactCTA from '@firsttable/web-components/molecules/ContactCTA';
import ReviewSummary from '@firsttable/web-components/organisms/ReviewSummary/ReviewSummary';
import Divider from '@firsttable/web-components/atoms/Divider';
import ReviewForm from '../components/organisms/Forms/ReviewForm';
import TwoColumnLayout from '../layouts/TwoColumnLayout';
import LoadingLayout from '../layouts/LoadingLayout';
import MissingRoute from '../layouts/MissingRoute';
import { isGlobalSite } from '../helpers/data';
import SEO from '../components/seo';
import MisuseReview from '../components/organisms/Modal/MisuseReview';
import { NotFoundLayout } from './404';
import NavLink from '../components/atoms/NavLink';
import NavButton from '../components/atoms/NavButton';

const BOOKING_FOR_REVIEW = gql`
  query getLiveBookingForReview($key: String) {
    booking: Booking(key: $key) {
      id
      date
      time
      people
      price
      specialConditions
      hasReview
      misuseReview {
        id
        comment
      }
      restaurant {
        id
        title
      }
    }
  }
`;

const ReviewLayout = ({ title, col1, col2 }) => (
  <TwoColumnLayout title={title} col1={col1} col2={col2} />
);

ReviewLayout.propTypes = {
  title: PropTypes.string,
  col1: PropTypes.node,
  col2: PropTypes.node,
};

const ReviewContent = ({ bookingKey, action }) => {
  const { loading, error, data, refetch } = useQuery(BOOKING_FOR_REVIEW, {
    ssr: false,
    variables: { key: bookingKey },
    fetchPolicy: 'network-only',
  });

  const updateAction = action === 'update';

  if (loading) return <LoadingLayout showFooter showMenu />;
  if (error || !data.booking) {
    return <NotFoundLayout />;
  }

  const { booking } = data;
  if (booking.misuseReview && updateAction) {
    return (
      <ReviewLayout
        col1={
          <>
            <Title>Misuse Review</Title>
            <Text fontSize="s">
              Your review has been marked as misuse as it doesn’t comply with
              our review guidelines. <br />
              To get your review published as quickly as possible, please refer
              to{' '}
              <Link NavComponent={NavLink} to="/review-guidelines/" underline>
                First Table's Review Guidelines
              </Link>{' '}
              and update your feedback accordingly.
            </Text>
            <Title size="h5" mb="13px">
              Your Review
            </Title>
            <Text mb="3px">
              <em>{booking.misuseReview && booking.misuseReview.comment}</em>
            </Text>
            <Divider mt="20px" mb="20px" />
            <Title size="h5" mb="13px">
              Update your review
            </Title>
            <MisuseReview booking={booking} refetch={refetch} />
          </>
        }
        col2={<ReviewSummary NavLinkComponent={NavLink} />}
      />
    );
  }

  if (booking.hasReview) {
    return (
      <ReviewLayout
        col1={
          <>
            <Title>Review Complete</Title>
            {updateAction ? (
              <Text>This review has been updated.</Text>
            ) : (
              <Text>This booking has already been reviewed.</Text>
            )}
          </>
        }
        col2={<ContactCTA NavButtonComponent={NavButton} />}
      />
    );
  }
  return (
    <TwoColumnLayout
      title={`Review ${booking.restaurant.title}`}
      col1={<ReviewForm booking={booking} refetch={refetch} />}
      col2={<ReviewSummary booking={booking} NavLinkComponent={NavLink} />}
    />
  );
};

ReviewContent.propTypes = {
  bookingKey: PropTypes.string,
};
ReviewContent.defaultProps = {
  bookingKey: '',
};

const Review = () => {
  if (isGlobalSite) {
    return <SEO meta={[{ 'http-equiv': 'refresh', content: '0;url=/' }]} />;
  }

  return (
    <Router>
      <ReviewContent path="/review/:bookingKey/:action" />
      <ReviewContent path="/review/:bookingKey" />
      <ReviewContent path="/review/" />
      <MissingRoute default />
    </Router>
  );
};

Review.propTypes = {};
Review.defaultProps = {};

export default Review;
